<template>
  <div class="fitment-preview">
    <van-nav-bar
      title="装修预览"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="应用"
      @click-right="storeUse"
    />

    <top-small
      v-if="fitment.storeType === '1'"
      :value="fitment.headerContent"
    ></top-small>
    <top-big
      v-else-if="fitment.storeType === '2'"
      :value="fitment.headerContent"
    ></top-big>

    <search-bar></search-bar>

    <div v-for="(item, index) in fitment.list" :key="index">
      <notice-bar
        v-if="item.modulesType == 4 && item.styleType == 1"
        :value="item.childList"
      ></notice-bar>
      <navigate-bar
        v-if="item.modulesType == 2"
        :static="true"
        :value="item.childList"
      ></navigate-bar>
      <goods-big
        v-if="item.modulesType == 1 && item.styleType == 3"
        :static="true"
        :value="item.childList"
      ></goods-big>
      <goods-list
        v-if="item.modulesType == 1 && item.styleType == 2"
        :static="true"
        :value="item.childList"
      ></goods-list>
      <goods-two
        v-if="item.modulesType == 1 && item.styleType == 1"
        :static="true"
        :value="item.childList"
      ></goods-two>
      <advertise-swipe
        v-if="item.modulesType == 3 && item.styleType == 3"
        :static="true"
        :value="item.childList"
      ></advertise-swipe>
      <advertise-big
        v-if="item.modulesType == 3 && item.styleType == 1"
        :static="true"
        :value="item.childList"
      ></advertise-big>
      <advertise-two
        v-if="item.modulesType == 3 && item.styleType == 2"
        :static="true"
        :value="item.childList"
      ></advertise-two>
      <advertise-slide-big
        v-if="item.modulesType == 3 && item.styleType == 5"
        :static="true"
        :value="item.childList"
      ></advertise-slide-big>
      <advertise-slide-little
        v-if="item.modulesType == 3 && item.styleType == 4"
        :static="true"
        :value="item.childList"
      ></advertise-slide-little>
    </div>
  </div>
</template>

<script>
import searchBar from "@/components/fitment-box/search-bar";

import topSmall from "@/components/creator-box/top-small";
import topBig from "@/components/creator-box/top-big";
import noticeBar from "@/components/creator-box/notice-bar";
import navigateBar from "@/components/creator-box/navigate-bar";
import goodsBig from "@/components/creator-box/goods-big";
import goodsList from "@/components/creator-box/goods-list";
import goodsTwo from "@/components/creator-box/goods-two";
import advertiseSwipe from "@/components/creator-box/advertise-swipe";
import advertiseBig from "@/components/creator-box/advertise-big";
import advertiseTwo from "@/components/creator-box/advertise-two";
import advertiseSlideBig from "@/components/creator-box/advertise-slide-big.vue";
import advertiseSlideLittle from "@/components/creator-box/advertise-slide-little.vue";

import { mapState } from "vuex";

import { storeUse } from "@/api/fitment";
export default {
  name: "fitmentPreview",
  components: {
    searchBar,
    topSmall,
    topBig,
    noticeBar,
    navigateBar,
    goodsBig,
    goodsList,
    goodsTwo,
    advertiseSwipe,
    advertiseBig,
    advertiseTwo,
    advertiseSlideBig,
    advertiseSlideLittle,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["fitment"]),
  },
  methods: {
    storeUse() {
      let modulesDTOList = [];
      this.fitment.list.map((x, y) => {
        let o = {
          modulesType: x.modulesType,
          styleType: x.styleType,
          sort: y + 1,
        };
        let childList = [];
        if (x.modulesType == 1) {
          x.childList.map((i) => {
            childList.push({ productId: i.id });
          });
        } else if (x.modulesType == 2) {
          x.childList.map((i) => {
            childList.push({
              navigationType: i.navigationType,
              navigationName: i.navigationName,
              filedId: i.filedId,
              picUrl: i.picUrl,
            });
          });
        } else if (x.modulesType == 3) {
          x.childList.map((i) => {
            childList.push({
              advertType: i.advertType,
              filedId: i.filedId,
              picUrl: i.picUrl,
            });
          });
        } else {
          childList = x.childList;
        }
        o.childList = childList;
        modulesDTOList.push(o);
      });
      let obj = {
        shopBasicDTO: {
          storeName: this.fitment.headerContent.storeName,
          storeDescribe: this.fitment.headerContent.storeDescribe,
          logoUrl: this.fitment.headerContent.logoUrl,
          signUrl: this.fitment.headerContent.signUrl,
          storeType: this.fitment.storeType,
          sort: 1,
        },
        modulesDTOList: modulesDTOList,
      };
      storeUse(obj).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$toast("应用成功");
          this.$store.commit("setList", []);
          this.$store.commit("setHeaderContent", {
            signUrl: null,
            logoUrl: null,
            storeName: null,
            storeDescribe: null,
          });
          // this.$router.go(-2);
          this.close()
        }
      });
    },
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-preview {
  background-color: #f6f6f6;
}
</style>